import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadosProductosService {

  constructor() { }
  private _panelState = new BehaviorSubject<boolean>(false);
  public panelState$ = this._panelState.asObservable();

  setPanelState(state: boolean) {
    this._panelState.next(state);
  }
}
