import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { LoginService } from '../../services/login/login.service';
import { environment } from '../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ExcedentesService {
  urlApi: string;
  body:any;

  get headers(): HttpHeaders {
    return new HttpHeaders()
    .set('Content-Type', 'application/json;charset=UTF-8')
    .set('Authorization', 'bKZViEZkbc/vv0DdOJPyRQdU2YEM+iygFIK/zeQqzxs277Am+Z/DaYOXdcAI182ciVo9YYHZN7H0y97L8106t3p7AoHsjvqLstmfxpGwkepHZfRSgYKz6JBfE8lNtWvMPXorB2DFKyh5YqlYOeQ5RcdrG9wt/CKNJngnr7rx27XauU4EDvZcR/ydm9X8oa0Vl+gtV67hOsNCMADf1P473TTwHDYK+RvC8yFS1XsbSX+zIkbsFawgXxCCtieImt8n5uoHXrQ8JyqkStXBm9BFee4B6gpq6W8Jnjhwx1i07JVEpkBT9yskBkJZuKtpPHoB')
  }
  constructor(    
    private http: HttpClient,
    private _globalService: GlobalService,
    private almnService:AlmacenamientoService) { }


    getExcedentes (IdContrato?): Observable<any>{
      this.refrescarApi();
     this.body = {
      IdContrato: IdContrato
     }
      return this.http.post<any>(this.urlApi + 'Facturas/ExcedentesAutoconsumo', this.body, this._globalService.getHttpHeaders());
    }

    refrescarApi(){
      if(this.urlApi != this._globalService.getUrlApi()){
        this.urlApi= this._globalService.getUrlApi();
      }
    }
}
