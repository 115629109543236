<div id="cargarPortal1">AQUÍ NO</div>
<div id="cargarPortal2">
    <div>
        <img src="assets/img/logo.png" width="360px"> 
    </div>
    <mat-spinner></mat-spinner>
    <h1>Oficina Virtual</h1>
</div>
<mat-drawer-container class="menuSlider" autosize>
    <mat-drawer #drawer [mode]="sliderMode" [opened]="sliderOpen" id="ppeeee">

        <header id="menuHeader">
            <img src="assets/img/logo.png" id="logoEmpresa" alt="Avatar">
        </header>

        <header id="menuUsuarioHeader" class="menuUsuario">
            <section>
                <img src="assets/img/user.png" width="45px" height="auto">
                <div>
                    <span id="menuNombreCliente"><b>{{nombreCliente}}</b></span><br>
                    <span id="menuCodigoCliente"><b>Código cliente:</b> {{codigoCliente}}</span>
                </div>
                <i class="fas fa-power-off" [routerLink]="['login']"></i>
            </section>
        </header>

        <nav id="menu" class="nav-main">
            <div class="headerMenuSlider">MENÚ</div>

            <ul class="nav nav-main">
                <li class="nav-parent">
                    <a class="nav-link" (click)="cargarDesplegable(-1)" [routerLink]="['resumen']" routerLinkActive="menuItemActive">
                        <i class="fal fa-layer-group"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li class="nav-parent" routerLinkActive="menuItemActive1">
                    <a class="nav-link menuItemActiveFather" (click)="cargarDesplegable(0)">
                        <i class="fal fa-user-circle"></i>
                        <span>Tu perfil</span>
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <ul class="nav nav-children">
                        <li>
                            <a class="nav-link" [routerLink]="['perfil']" routerLinkActive="menuItemActiveChild">
                                Datos personales
                            </a>
                        </li>
                        <li>
                            <a class="nav-link" [routerLink]="['informacion/contratos']"
                                routerLinkActive="menuItemActiveChild">
                                Contratos
                            </a>
                        </li>
                        <li>
                            <a class="nav-link" [routerLink]="['informacion/facturas']"
                                routerLinkActive="menuItemActiveChild">
                                Facturas
                            </a>
                        </li>
                    </ul>
                    <!-- </li> -->
                <li class="nav-parent" routerLinkActive="menuItemActive1">
                    <a class="nav-link menuItemActiveFather" (click)="cargarDesplegable(1)">
                        <i class="fal fa-chart-line"></i>
                        <span>Tu consumo</span>
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <ul class="nav nav-children">
                        <!-- <li>
                            <a class="nav-link" [routerLink]="['consumo/autolectura']"
                                routerLinkActive="menuItemActiveChild">
                                Enviar autolectura
                            </a>
                        </li> -->
                        <li>
                            <a class="nav-link" [routerLink]="['consumo/hisoricolectura']"
                                routerLinkActive="menuItemActiveChild">
                                Lecturas
                            </a>
                        </li>
                        <li>
                            <a class="nav-link" [routerLink]="['consumo/historico']" routerLinkActive="menuItemActiveChild">
                                Histórico
                            </a>
                        </li>
                        <!-- <li>
                            <a class="nav-link" [routerLink]="['consumo/prevision']" routerLinkActive="menuItemActiveChild">
                                Previsión
                            </a>
                        </li> -->
                        <li>
                            <a class="nav-link" [routerLink]="['consumo/cadena']" routerLinkActive="menuItemActiveChild">
                                Código Amiwi
                            </a>
                        </li>
                        <li *ngIf="false">
                            <a class="nav-link" [routerLink]="['consumo/impacto']" routerLinkActive="menuItemActiveChild">
                                Tu impacto
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- <li class="nav-parent">
                    <a class="nav-link" (click)="cargarDesplegable(-1)" routerLinkActive="menuItemActive"> 
                        <i class="fal fa-headset"></i>
                        <span>Asistente virtual</span>
                    </a>
                </li> -->
                <li class="nav-parent" *ngIf="isPrecarga">
                    <a class="nav-link" (click)="cargarDesplegable(-1)" [routerLink]="['wallet']" routerLinkActive="menuItemActive">
                        <div id="saldoMenu">
                            <section>
                                <i class="fal fa-hand-holding-usd"></i>
                                <span>Tu Saldo</span>
                            </section>
                            <div>{{importeCliente}}</div>
                        </div>
                    </a>
                </li>
                <li class="nav-parent" routerLinkActive="menuItemActive1">
                    <a class="nav-link menuItemActiveFather" (click)="cargarDesplegable(2)">
                        <i class="fal fa-credit-card"></i>
                        <span>Transacciones</span>
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <ul class="nav nav-children">
                        <li>
                            <a class="nav-link" [routerLink]="['transacciones/cambios']"
                                routerLinkActive="menuItemActiveChild">
                                Cambios
                            </a>
                        </li>
                        <li>
                            <a href="{{nuevoContratoEntorno}}" target="_blank" class="nav-link">
                                Nuevo contrato
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-parent" routerLinkActive="menuItemActive1" *ngIf="false">
                    <a class="nav-link menuItemActiveFather" (click)="cargarDesplegable(3)">
                        <i class="fal fa-comments"></i>
                        <span>Contacto</span>
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <ul class="nav nav-children">

                    </ul>
                </li>
                <li class="nav-parent">
                    <a class="nav-link" (click)="cargarDesplegable(-1)" [routerLink]="['bateria-virtual']" routerLinkActive="menuItemActive">
                        <i class="fal fa-battery-three-quarters"></i>
                        <span>Batería Virtual</span>
                    </a>
                </li>
            </ul>
        </nav>


        <article id="menuUsuarioFooter" class="menuUsuario">
            <div class="headerMenuSlider">DATOS</div>

            <section>
                <img src="assets/img/user.png" width="45px" height="auto">
                <div>
                    <span id="menuNombreCliente"><b>{{nombreCliente}}</b></span><br>
                    <span id="menuCodigoCliente"><b>Código cliente:</b> {{codigoCliente}}</span>
                </div>
            </section>
            <div id="verFacturas">
                <button class="btn btnStyle_1" [routerLink]="['login']">Salir &nbsp; <i class="fal fa-sign-out-alt"></i></button>
            </div>
        </article>
    </mat-drawer>
    <mat-drawer-content>
        <button id="menuButton1" (click)="drawer.toggle(); checkSlider(drawer)"
            *ngIf="url !== 'login' && url !== 'recordarcontrasena'" [disabled]="false">
            <i *ngIf="mostrarBoton" class="fas fa-angle-right"></i>
            <i *ngIf="!mostrarBoton" class="fas fa-angle-left"></i>
        </button>

        <div id="cabeceraMenu" *ngIf="url != 'login' && url != 'recordarcontrasena'">
            <i class="fal fa-bars" (click)="drawer.toggle(); checkSlider(drawer)"></i>
            <img src="assets/img/logo.png" alt="Avatar">
        </div>

        <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>