<app-header></app-header>

<div class="pageContent">
  <div id="container">
    <mat-card class="cardsContents cardCodigo">
      <h2 class="tituloCards">Tu código</h2>

      <div class="cardCentradoCadena">
        <section>
          <fieldset>
            <legend>Cópialo y compártelo</legend>

            <div>
              <b>{{codigoPromocional}}</b>
              <i class="fad fa-check-circle" title="Código copiado correctamente"></i>
              <span (click)="copiarCadena()" id="copiarCadena">Copiar</span>
            </div>
          </fieldset>

          <div id="redesSociales" *ngIf="false">
            <a href="https://www.facebook.com/people/Wekiwisolar/100057404081528/" target="_blank"><i class="fab fa-facebook" style="color: #3b5998;"></i></a>
            <a href="https://www.facebook.com/people/Wekiwisolar/100057404081528/" target="_blank"><i class="fab fa-facebook-messenger" style="color: #0088cc;"></i></a>
            <a href="https://api.whatsapp.com/send" target="_blank"><i class="fab fa-whatsapp" style="color: #25D366;"></i></a>
            <a href="https://twitter.com/SolarWekiwi" target="_blank"><i class="fab fa-twitter" style="color: #00acee;"></i></a>
          </div>
        </section>

        <br>

        <span>*Recuerda que es personal, único e intransferible</span>
      </div>
    </mat-card>

    <mat-card class="cardsContents cardPrevisionGasto">
      <h2 class="tituloCards">Tus Amiwis</h2>

      <div class="cardCentradoCadena">
        <section>
          <article>
            <p id="textoAmigosReferidos">{{usosPromocional}}</p>
            <p id="textoAmigosReferidosBorder"></p>
            <div class="puntos" id="punto1"></div>
            <div class="puntos" id="punto2"></div>
            <div class="puntos" id="punto3"></div>
            <div class="puntos" id="punto4"></div>
          </article>
    
          <div id="textoCadena" *ngIf="usosPromocional == 0">
            <span>¡Vaya! <!--<i class="fal fa-frown"></i>--></span>
            <p>Todavía no has compartido tu código con ningún amigo. 
            <br><br> ¿Y si empiezas hoy? 
            <br><br> Recuerda que ambos conseguiréis un descuento en vuestra próxima factura.</p>
          </div>

          <div id="textoCadena" *ngIf="usosPromocional > 0">
            <!-- <span>¡Enhorabuena! <i class="fal fa-smile"></i></span> -->
            <p>Nos encanta saber que tus amigos y tu queréis darle la vuelta a la forma de hacer las cosas. 
              Continúa haciendo crecer la comunidad Wekiwi y benefíciate de ello. 
            <br><br> Este es el número de personas que has metido en tu círculo Wekiwi: {{usosPromocional}}</p>
          </div>
        </section>
      </div>
      
    </mat-card>
  </div>

  <mat-card class="cardsContents cardFuncionamiento">
    <h2 class="tituloCards">Funcionamiento</h2>

    <article>
      ¡Pon patas arriba a tus amigos y familia! ¿Cómo? Añádeles a “tu cadena” Wekiwi. Comparte tu experiencia con ellos y si también 
      quieren disfrutar de los beneficios que en Wekiwi ofrecemos, solamente tienen que darse de alta como cliente añadiendo tu
      código personal en el proceso de inscripción.<br><br> En ese mismo momento ambos recibiréis un descuento de 5€ en vuestra próxima factura. 
      Puedes meter a tantos amigos o familiares que quieras, eso ya depende de ti.
    </article>
  </mat-card>

  <!-- dejo comentado y oculto card por WEBEV-2154 -->
  <!-- <mat-card class="cardsContents cardWekiwiPeople" *ngIf="usosPromocional == 0"> --> 
  <mat-card class="cardsContents cardWekiwiPeople" *ngIf="ocultarWekiwiPeople">
    <h2 class="tituloCards">Wekiwi people</h2>

    <article>
      <img src="assets/img/wekiwiPeople.png" alt="WekiwiPeople">

      <div>
        <p>¿Quieres <b>ganar dinero</b> y no solo recibir descuentos en tu factura de la luz?</p>
        <p>Con Wekiwi People puedes formar tu propia red comercial y empezar a ganar desde el primer contrato que cierres</p>
      </div>
    </article>
    
    <div class="buttonFooterCards">
      <a href="https://wekiwi.es/people/" target="_blank">
        <button class="btn btnStyle_2">Saber más</button>
      </a>
    </div>
    
  </mat-card>

  <!-- <mat-card class="cardsContents cardWekiwiPeople" *ngIf="usosPromocional > 0"> -->
  <mat-card class="cardsContents cardWekiwiPeople" *ngIf="ocultarWekiwiPeople">
    <h2 class="tituloCards">Wekiwi people</h2>

    <article>
      <img src="assets/img/wekiwiPeople.png" alt="WekiwiPeople">

      <div>
        <h1>{{30 * usosPromocional}}€</h1>
        <h3>¡Enhorabuena!</h3>
        <p><b>Ya formas parte de la red Wekiwi People. <br> LLevas {{30 * usosPromocional}}€ ahorrados en tu hucha</b></p>
      </div>
    </article>
    
    <div class="buttonFooterCards">
      <button class="btn btnStyle_2">Saber más</button>
    </div>
    
  </mat-card>
</div>