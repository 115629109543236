<app-header></app-header>

<div class="pageContent">
  <div class="label-container">
    <div class="label-tag">
      Contratos: <span class="label-tag-span">{{contratosTotal}}</span>
    </div>
    <div class="label-tag-active">
      Contratos activos: <span class="label-tag-span">{{contratoActivo}}</span>
    </div>
  </div>
    <div style="padding: 1rem;">
      <mat-card class="tablaListadoContratos">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <table>
                <thead>
                  <tr>
                    <th>{{'Contrato' | translate}} Nº</th>
                    <th>{{'Situación' | translate}}</th>
                    <th>{{'Tipo' | translate}}</th>
                    <th>{{'Tarifa' | translate}}</th>
                    <th>{{'Cliente' | translate}}</th>
                    <th>{{'Dirección' | translate}}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>{{dataSource[0].CodigoContrato}}</div>
                      <span title="dataSource[0].Entorno">
                        <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                        <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                      </span>
                    </td>
                    <td title="dataSource[0].ContratoSituacion">
                      {{dataSource[0].ContratoSituacion}} 
                      <i>-</i>
                      <span>{{dataSource[0].Tarifa}}</span>
                    </td>
                    <td>
                      <span title="dataSource[0].Entorno">
                        <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                        <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                      </span>
                    </td>
                    <td title="dataSource[0].NombreCliente">{{dataSource[0].Tarifa}}</td>
                    <td title="dataSource[0].NombreCliente"><span>{{dataSource[0].NombreCliente}}</span></td>
                    <td title="{{dataSource[0].DireccionSuministro}}"><span>{{dataSource[0].DireccionSuministro}}</span></td>
                  </tr>
                </tbody>
              </table>
            </mat-expansion-panel-header>
            <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">
              <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
              <table>
                <thead>
                  <tr>
                    <th>{{'Contrato' | translate}} Nº</th>
                    <th>{{'Situación' | translate}}</th>
                    <th>{{'Tipo' | translate}}</th>
                    <th>{{'Tarifa' | translate}}</th>
                    <th>{{'Cliente' | translate}}</th>
                    <th>{{'Dirección' | translate}}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato)">
                  <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource[0].CodigoContrato"> 
                    <td>
                      <div >{{contrato.CodigoContrato}}</div>
                      <span title="contrato.Entorno">
                        <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                        <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                      </span>
                    </td>
                    <td>
                      {{contrato.ContratoSituacion}}
                      <i>-</i>
                      <span>{{contrato.Tarifa}}</span>
                    </td>
                    <td>
                      <span title="contrato.Entorno">
                        <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                        <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                      </span>
                    </td>
                    <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                    <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                    <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card>
    </div>
    <div style="height: 100%; padding: 1rem;" >
      <h2>Gestión de Excedentes</h2>
      <mat-card class="cardsContents" *ngIf="excedentes && hayFacturacion">
          <table style="width: 100%;" mat-table [dataSource]="dataSourceE" matSort >
              <ng-container matColumnDef="Numero">
                <th mat-header-cell *matHeaderCellDef class="textCenter headerCell ">{{'Número factura' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="textCenter bold">{{element.NumeroFactura ? element.NumeroFactura : '0'}}</td>
              </ng-container>
            
              <ng-container matColumnDef="FechaFactura">
                <th mat-header-cell *matHeaderCellDef class="textCenter headerCell ">{{'Fecha Factura' | translate}}</th>
                <td mat-cell *matCellDef="let element"class="textCenter bold">{{element.FechaFactura ? ( element.FechaFactura | date:'dd/MM/yyyy') : '0'}}</td>
              </ng-container>
            
              <ng-container matColumnDef="SaldoInicial">
                  <th mat-header-cell *matHeaderCellDef class="textCenter headerCell">{{'Saldo Inicial' | translate}}  
                    <i class="fa fa-info-circle info-icon"  matTooltip="Es el saldo con el que comienza tu batería virtual al inicio del período. Corresponde al importe acumulado de excedentes no utilizados en meses anteriores (si los hubiera)." matTooltipPosition="above"></i></th>
                  <td mat-cell *matCellDef="let element"class="textCenter">{{element.SaldoAnterior ? element.SaldoAnterior : '0'}} €</td>
              </ng-container>
              
              <ng-container matColumnDef="Excedentes">
                  <th mat-header-cell *matHeaderCellDef class="textCenter headerCell">{{'Excedentes' | translate}}
                    <i class="fa fa-info-circle info-icon"  matTooltip="Es la energía que generas pero no consumes (energía que se vierte a la red)." matTooltipPosition="above"></i>
                  </th>
                  <td mat-cell *matCellDef="let element" class="textCenter">{{element.ExcedenteskWhTotal ? element.ExcedenteskWhTotal : '0'}} kWh</td>
              </ng-container>
              
              <ng-container matColumnDef="SaldoGenerado">
                  <th mat-header-cell *matHeaderCellDef class="textCenter headerCell">{{'Saldo Generado' | translate}} 
                      <i class="fa fa-info-circle info-icon"  matTooltip="Es el valor en euros de los excedentes." matTooltipPosition="above"></i>
                  </th>
                  <td mat-cell *matCellDef="let element" class="textCenter">{{element.SaldoGenerado ? element.SaldoGenerado : '0'}} €</td>
              </ng-container>
              
              <ng-container matColumnDef="CompensacionSimplificada">
                  <th mat-header-cell *matHeaderCellDef class="textCenter headerCell">{{'Compensacion Simplificada' | translate}} 
                    <i class="fa fa-info-circle info-icon"  matTooltip="Es la parte de tu consumo que se ha pagado gracias al saldo acumulado en tu batería virtual." matTooltipPosition="above"></i>
                  </th>
                  <td mat-cell *matCellDef="let element" class="textCenter">{{element.SaldoCompensacionSimplificada ? element.SaldoCompensacionSimplificada : '0'}} €</td>
              </ng-container>
              
              <ng-container matColumnDef="DescuentoTotal">
                  <th mat-header-cell *matHeaderCellDef class="textCenter headerCell">{{'Descuento Total' | translate}}</th>
                  <td mat-cell *matCellDef="let element" class="textCenter bold">{{element.SaldoDescuentoFinal ? element.SaldoDescuentoFinal : '0'}} €</td>
              </ng-container>
                
            
              <ng-container matColumnDef="Saldo" >
                <th mat-header-cell *matHeaderCellDef class="textRight headerCell">{{'Saldo' | translate}}</th>
                <td mat-cell *matCellDef="let element" class="textRight bold">{{element.SaldoFinal ? element.SaldoFinal : '0'}} €</td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>        
      </mat-card>
      <mat-card class="cardsContents textCenter" *ngIf="!excedentes">
        <span >UPS! Este contrato no cuenta con batería virtual.</span>
      </mat-card>
      <mat-card class="cardsContents textCenter" *ngIf="excedentes && !hayFacturacion">
        <span >El acceso a tu batería virtual se activará automáticamente cuando recibas tu primera factura.</span>
      </mat-card>
    </div>
    
</div>