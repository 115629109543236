import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { GlobalService } from '../../../services/config/global/global.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { NgModel, FormControl, NgForm, FormGroup, Validators, FormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { Contrato } from '../../../interfaces/contrato';
import { environment } from 'src/environments/environment';
import { CambiosDatosTitularComponent } from '../../dialogs/cambios-datos-titular/cambios-datos-titular.component';
import { CambiosDatosPagoContactoEnvioComponent } from '../../dialogs/cambios-datos-pago-contacto-envio/cambios-datos-pago-contacto-envio.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PasswordService } from 'src/app/services/password/password.service';
import { LoginDialogComponent } from '../../dialogs/login-dialog/login-dialog.component';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  dataSource2: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];

  CUPS: any;
  TipoCliente: string;
  CupsMultipunto: string;
  dataSource3: any;
  RazonSocialCliente: string;
  NombreCliente: string;
  Apellido1Cliente: string;
  Apellido2Cliente: string;
  CiudadCliente: string;
  CodigoPostalCliente: string;
  ProvinciaCliente: string;
  ColectivoCliente: string;
  DireccionCliente: string;
  IdCallejeroCliente: string;
  IdCiudadCliente: string;
  IdCliente: string;
  Contrato: string;
  IdProvinciaCliente: string;
  IdentificadorClienteDNI: string;
  IdentificadorClienteEmail: string;
  IdentificadorRepresentante: string;
  IdColectivoRepresentante: string;
  NombreRepresentante: string;
  ColectivoRepresentante: string;
  ComentarioRepresentante: string;
  MetodosPago: any[] = [];
  DatosContacto: any[] = [];
  DatosEnvio: any[] = [];
  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  columnasUltimosContratos: string[] = [];
  contratoSeleccionado: any;
  dataSourceUltimosContratos = new MatTableDataSource<Contrato>();
  Email: any;

  IsAdministrador: any;

  MostrarBotonModificiacionDatosPerfil: boolean;
  dialogRef: any;

  TamanoListadoCupsCliente: any;
  DatosCupsMultipunto: any;
  Entorno: any;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;
  IBAN: any;
  Tlf: any;
  NombrePagador: any;
  direccionFacturacion: any;

  datosCupsMonopunto: any;
  cupsFiltered: any[] = [];
  tokenvalidado: any;
  OcultaTipoContacto1 = environment.OcultaTipoContacto1;
  OcultarContactosEleia = environment.OcultarContactosEleia;

  editarDatosPersonales: boolean = false;
  editarFacturacion: boolean = false;
  editarPrevision: boolean = false;
  editarSuministro: boolean = false;

  selectedCups = new FormControl();

  //Variables Contratos
  dataSource: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];
  ultimoContratoActivo: Contrato;
  cambioPass: FormGroup;
  isProductoPrecarga: any = true;
  filtroTabla: any = '';

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _globalService: GlobalService,
    private almnService:AlmacenamientoService,
    private _funcionesService: FuncionesService,
    private _contratosService: ContratosService,
    private _passwordService: PasswordService,
    public dialog: MatDialog,
    private fb: FormBuilder,
  ) {
    this.TipoCliente = this.almnService.getValue('TipoCliente');
    this.cupsFiltered = JSON.parse(this.almnService.getValue('cupsFiltered'));
  }

  ngOnInit() {
    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;

      if (respuesta.__zone_symbol__value) {
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);
    
    this.MostrarBotonModificiacionDatosPerfil = environment.MostrarBotonModificiacionDatosPerfil;

    // this.TamanoListadoCupsCliente = this._loginService.datosTitular.CUPS.length;
    if (this.cupsFiltered && this.cupsFiltered[0] && this.cupsFiltered[0].IdCups != '') {
      this.BusquedaNuevoCups(this.cupsFiltered[0].IdCups);
      this.CupsMultipunto = this.cupsFiltered[0].IdCups;
    }

    this.cambioPass = this.fb.group({
      contrasenaActual: ['', Validators.required],
      contrasenaNueva: ['', [Validators.required, Validators.minLength(4)]],
      contrasenaNuevaD: ['', [Validators.required]],
    },
    {
      validators: [this.validateEmails('contrasenaNueva', 'contrasenaNuevaD'), this.checkActualPass('contrasenaActual')]
    });
  }

  comprobarDatosContratos() {
    console.log('PERFIL CONTROLLER');

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")))
    } else {
      this.cargarListadoContratos();
    }      
  }

  validateEmails(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.matching) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  checkActualPass(controlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);

      if (control.errors && !control.errors.matching) {
        return null;
      }
      if (control.value !== this._funcionesService.decodificarToken(JSON.parse(this.almnService.getValue('p')))) {
        controls.get(controlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  cargarListadoContratos() {
    this._contratosService
      .getListadoContratos(this.almnService.getValue('idCliente'))
      .subscribe(async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data))
        this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
        this.listarContratos(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  listarContratos(auxContratos) {
    // Listado contratos
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);
  }

  filtraListadoContratos(contrato, ajuste) {
    if(ajuste){this.almnService.clear('soft')}
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { console.log(); return cont.IdContrato === contrato })[0];
    // this.buscarCups(this.ultimoContratoActivo); //
    this.dataSource[0] = this.ultimoContratoActivo;
    this.isProductoPrecarga = this.ultimoContratoActivo.IsPrecarga;
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));

    if (this._loginService.datosTitular === undefined) {

      this._loginService.getLogin(this._funcionesService.decodificarToken(JSON.parse(this.almnService.getValue('u'))),
        this._funcionesService.decodificarToken(JSON.parse(this.almnService.getValue('p'))))
        .toPromise()
        .then(async data => {

          this._loginService.datosTitular = this._funcionesService.decodificarToken(data);
          // console.log(this._loginService.datosTitular);
          this.loadData();

        });
    } else {
      this.loadData();
    }
    // TODO lanzar funcion recuperacion datos toda la pagina
  }

  BusquedaNuevoCups(idCups) {

    this._contratosService.getDatosCups(idCups).subscribe(
      async datosCupsMultipunto => {

        this.DatosCupsMultipunto = this._funcionesService.decodificarToken(datosCupsMultipunto);

        // console.log(this.DatosCupsMultipunto);
        // console.log(Object.keys(this.DatosCupsMultipunto).length);

        if (this.DatosCupsMultipunto) {
          // console.log(this.DatosCupsMultipunto);
          this.MetodosPago = this.DatosCupsMultipunto.MetodosPago[0];

          if (this.DatosCupsMultipunto.MetodosPago[0].TipoCobroPagador == 'DOMICILIADO 7') {
            this.DatosCupsMultipunto.MetodosPago[0].TipoCobroPagador = 'Domiciliado'
          }
          this.DatosContacto = [];
          this.DatosContacto = this.DatosCupsMultipunto.Contactos;
          // console.log(this.DatosContacto);
          this.DatosEnvio = this.DatosCupsMultipunto.Envios[0];
          // this.Email = this._loginService.datosTitular.EmailAdministrador;
        }
      });
  }

  loadData() {
    // console.log(this.dataSource[0]);
    // console.log(this._loginService);
    //Datos del titular 
    this.NombreCliente = this.dataSource[0].NombreCliente;
    this.Apellido1Cliente = this.dataSource[0].PrimerApellido;
    this.Apellido2Cliente = this.dataSource[0].SegundoApellido;
    this.IdentificadorClienteDNI = this.dataSource[0].Identidad;
    this.RazonSocialCliente = this.dataSource[0].NombreCliente;
    this.Tlf = this.dataSource[0].Movil;

    //Datos pagador
    this.NombrePagador = this.dataSource[0]['NombrePagador'];
    this.IBAN = this.dataSource[0].IBAN;
    this.direccionFacturacion = this.dataSource[0]['DirecionFact'];

    //Dirección y representante
    this.CiudadCliente = this._loginService.datosTitular.CiudadCliente;
    this.CodigoPostalCliente = this._loginService.datosTitular.CodigoPostalCliente;
    this.ProvinciaCliente = this._loginService.datosTitular.ProvinciaCliente;
    this.DireccionCliente = this._loginService.datosTitular.DireccionCliente;
    this.DatosEnvio = this._loginService.datosTitular.Envios[0];
    this.ColectivoCliente = this._loginService.datosTitular.ColectivoCliente;
    this.NombreRepresentante = this._loginService.datosTitular.NombreRepresentante;
    this.ColectivoRepresentante = this._loginService.datosTitular.ColectivoRepresentante;
    this.ComentarioRepresentante = this._loginService.datosTitular.ComentarioRepresentante;
    this.IdentificadorRepresentante = this._loginService.datosTitular.IdentificadorRepresentante;
    this.Email = this._loginService.datosTitular.IdentificadorClienteEmail;

    if (this._loginService.datosTitular.MetodosPago[0].TipoCobroPagador == 'DOMICILIADO 7') {
      this._loginService.datosTitular.MetodosPago[0].TipoCobroPagador = 'Domiciliado'
    }
  }

  filtrafacturas(contrato) {
    this.dataSource2[0] = contrato;
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(contrato)));
    this.NombreCliente = this.dataSource2[0].NombreCliente;
    this.Direccion = this.dataSource2[0].DireccionSuministro;
    this.Tarifa = this.dataSource2[0].Tarifa;
    this.CUPS = this.dataSource2[0].Cups;
    this.DireccionCliente = this.dataSource2[0].DirecionFact;
    this.Contrato = this.dataSource2[0].CodigoContrato;
    // TODO lanzar funcion recuperacion datos toda la pagina
  }

  openDialogCambiosDatosTitular() {

    let email = "";

    (this.DatosContacto.length > 0) ? email = this._funcionesService.buscarEmailContactos(this.DatosContacto) : null;

    this.dialogRef = this.dialog.open(CambiosDatosTitularComponent, {
      data: {
        'IsAdministrador': this.almnService.getValue('isAdminFincas'),
        'IdCliente': this.almnService.getValue('idCliente'),
        'Entorno': this.almnService.getValue('entorno'),
        'IdentificadorClienteDNI': this.IdentificadorClienteDNI,
        'RazonSocialCliente': this.RazonSocialCliente,
        'Apellido1Cliente': this.Apellido1Cliente,
        'Apellido2Cliente': this.Apellido2Cliente,
        'CodigoPostalCliente': this.CodigoPostalCliente,
        'ProvinciaCliente': this.ProvinciaCliente,
        'CiudadCliente': this.CiudadCliente,
        'DireccionCliente': this.DireccionCliente,
        'IdentificadorRepresentante': this.IdentificadorRepresentante,
        'ColectivoRepresentante': this.ColectivoRepresentante,
        'NombreRepresentante': this.NombreRepresentante,
        'EmailContacto': email,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }


  openDialogCambiosDatosPagoContactoEnvio() {

    let email = "";

    (this.DatosContacto.length > 0) ? email = this._funcionesService.buscarEmailContactos(this.DatosContacto) : null;

    this.dialogRef = this.dialog.open(CambiosDatosPagoContactoEnvioComponent, {
      data: {
        'IsAdministrador': this.almnService.getValue('isAdminFincas'),
        'IdCliente': this.almnService.getValue('idCliente'),
        'Entorno': this.almnService.getValue('entorno'),
        'IdentificadorClienteDNI': this.IdentificadorClienteDNI,

        'MetodosPago': this.MetodosPago,
        'DatosContacto': this.DatosContacto,
        'DatosEnvio': this.DatosEnvio,
        'EmailContacto': email

      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }

  modificarDatos(type) {
    switch (type) {
      case 'personales':

        break;

      case 'facturacion':

        break;

      case 'prevision':

        break;

      case 'suministro':

        break;

      case 'contraseña':
        this._passwordService.getCambioPassword(this.almnService.getValue('idCliente'), this.cambioPass.controls.contrasenaNueva.value, this.almnService.getValue('token')).subscribe(
          datos => {
            var respuesta = this._funcionesService.decodificarToken(datos);
            console.log(respuesta);
            // this.textoModal = this.respuesta.ResultadoUpdate;
            var textoModal = 'La contraseña se ha cambiado satisfactoriamente. Inicie sesión con su nueva contraseña.';
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                texto: textoModal,
                titulo: 'Cambio de Contraseña',
                estado: true
              }
            });

            this.router.navigate(['/login']);
          },
          err => {
            var textoModal = 'No se ha podido modificar la contraseña';
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                texto: textoModal,
                titulo: 'Error en Cambio Contraseña',
                estado: false
              }
            });
          });
        break;
    }
  }
}
