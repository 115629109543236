import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Contrato } from 'src/app/interfaces/contrato';
import { Excedentes } from 'src/app/interfaces/excedentes';
import { ExcedentesService } from 'src/app/services/excedentes/excedentes.service';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
@Component({
  selector: 'app-bateria-virtual',
  templateUrl: './bateria-virtual.component.html',
  styleUrls: ['./bateria-virtual.component.scss']
})
export class BateriaVirtualComponent implements OnInit {
  dataSourceE = new MatTableDataSource();
  displayedColumns: string[] = [
  'Numero', 'FechaFactura', 'SaldoInicial', 'Excedentes', 
    'SaldoGenerado', 'CompensacionSimplificada', 'DescuentoTotal', 'Saldo'
  ];
  //Variables Contratos
  dataSource: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  contratoActivo:any;
  contratosTotal: any;
  filtroTabla: any = '';
  contratoSeleccionado: any;
  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  excedentes: boolean = false;
  hayFacturacion:boolean = false;
  isFacturado:Boolean = false;
  constructor(
    private _excedentesService : ExcedentesService,
    private almnService:AlmacenamientoService,
    private _funcionesService: FuncionesService,
    private _contratosService: ContratosService,
  ){ }

  ngOnInit(): void {
    this.dataSourceE.sort = this.sort;
    this.dataSourceE.paginator = this.paginator;
    this.cargarListadoContratos();
  }

  cargarListadoContratos() {
    this._contratosService.getListadoContratos(this.almnService.getValue('idCliente')).subscribe(
      async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data))
        this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
        this.listarContratos(data);
      }, (err) => {
       this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  listarContratos(auxContratos) {
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado);
    
  }
  filtraListadoContratos(contrato) {
    this.isFacturado = false;
    this.hayFacturacion = false;
    this.excedentes = false;
    var sinFechaBaja = []
    this.ultimosContratos.forEach(element => {
      if(element.FechaBaja == null){
        sinFechaBaja.push(element)
      }
    });
    this.contratosTotal = this.ultimosContratos.length //Label total contratos
    this.contratoActivo = sinFechaBaja.length; //Label de contratos activos
    const contratoSeleccionado = this.ultimosContratos.find(cont => cont.IdContrato === contrato.IdContrato);
    
    if (contratoSeleccionado) {
      this.ultimoContratoActivo = contratoSeleccionado;
      this.dataSource[0] = this.ultimoContratoActivo;
      this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));

      var contratoVisible = this.almnService.getValue("ContratoVisible");
         contratoVisible = JSON.parse(atob(contratoVisible));
      } 
      
    if(contrato.IsBateriaVirtual == true){
      this.excedentes = true;
      this.cargarExcedentes(contrato);
    }


  }
  cargarExcedentes(contrato){
    this.dataSourceE.data = [] 

    this._excedentesService.getExcedentes(contrato.IdContrato).subscribe(
      async data => {
        this.dataSourceE.data = this._funcionesService.decodificarToken(data);    
        if(this.dataSourceE.data.length > 0){          
        this.isFacturado = this.dataSourceE.data.filter(x => { return Boolean(x['Facturado']) }).length > 0
        
          if (this.isFacturado) {
            this.hayFacturacion = true;
          }
        }
        
      }, (err) => {
        this.excedentes = false;
        this._funcionesService.controlStatusCallBacks(err, 'excedentes');
      });
  }

  
}
