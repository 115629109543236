import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { FuncionesService } from 'src/app/services/funciones/funciones.service';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-cadena',
  templateUrl: './cadena.component.html',
  styleUrls: ['./cadena.component.scss']
})

export class CadenaComponent implements OnInit {
  ocultarWekiwiPeople: true; //WEBEV-2154
  codigoPromocional: any = 'XXXXXXX';
  usosPromocional: number = 0;

  constructor(
    private _contratosService: ContratosService,
    private almnService:AlmacenamientoService,
    private _funcionesService: FuncionesService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    document.getElementsByClassName('fa-check-circle')[0]['style'].display = 'NONE';
    this._contratosService.getCadenaCliente(this.almnService.getValue('idCliente'))
    .subscribe(data => {
      const respuesta = this._funcionesService.decodificarToken(data);
      // console.log(respuesta)
      this.codigoPromocional = respuesta.CodigoPromocional;
      this.usosPromocional = respuesta.Usos || 0;
    }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'cadena');
    })
  }

  copiarCadena() {
    this._snackBar.open('Se ha copiado correctamente.','x', {
      horizontalPosition: 'end',
      duration: 3000,
      verticalPosition: 'bottom',
    });

    document.getElementsByClassName('fa-check-circle')[0]['style'].display = 'BLOCK';

    navigator.clipboard.writeText( this.codigoPromocional);
  }
}